import { useState, useEffect } from 'react';
import { generateId } from './utility';
import { QUEST_KEYS } from '../globalState/GlobalStateProvider';

export function usePersistedState(key, initialState) {
    const storageKey = `hamilton.${key}`;
    const hydrateState = () => {
        try {
            const persisted = window.localStorage.getItem(storageKey);
            const parsedRes = JSON.parse(persisted)

            if (parsedRes.version !== initialState.version) {
                console.warn('State version mismatch, returning initial state...')
                caches
                    .keys()
                    .then((names) => names.forEach(e => caches.delete(e)));
                window.location.reload(true);

                return initialState;
            }
            return parsedRes || initialState;
        } catch {
            console.warn('Failed to read current state version, returning initial state...')
            return initialState;
        }
    };

    const [state, setState] = useState(hydrateState);

    useEffect(() => {
        window.localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state, storageKey]);

    return [state, setState];
}

export function UseDeviceId() {
    const storageKey = `deviceId`;

    const generateDeviceId = () => {
        const Id = generateId(20);
        window.localStorage.setItem(storageKey, JSON.stringify(Id));
        return Id;
    }

    try {
        const persisted = window.localStorage.getItem(storageKey);
        const parsedRes = JSON.parse(persisted)

        if (!parsedRes) {
            console.warn('No Device ID set, generating...')
            return generateDeviceId();
        }
        return parsedRes;
    } catch {
        console.warn('No Device ID set, generating...')
        return generateDeviceId();
    }
}

export async function clearAllPersistedState() {
    const cache = await caches.open(QUEST_KEYS);
    const success = await cache.delete('/' + QUEST_KEYS);
    if (success) {
        console.log("Cache deletion successful");
    } else {
        console.log("Cache entry not found");
    }

    Object.keys(window.localStorage).forEach(key => {
        if (key.startsWith('hamilton.')) {
            window.localStorage.removeItem(key);
        }
    });
}